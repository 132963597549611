<template>
  <div>
    <v-row>
      <v-col lg="12">
      <incidenceliquidation :isAdmin="true"  :height="200">> 
      </incidenceliquidation>
      
      </v-col>
    </v-row>
  </div>
</template>

<script>
import incidenceliquidation from '@/views/FreshProduction/InidenceLiquidation/IncidenceLiquidation.vue';

export default {
  components: {        
    incidenceliquidation
    },

   data() {
    return {
          
    };
  },
  methods: {
 
  },
};
</script>
