import Service from "../Service";
import Vue from "vue";
const resource = "incLiquidation/"

export default {
    save(prflin, requestID) {
          return Service.post(resource + "save", prflin, {
          params: { requestID: requestID },
        });
      },
  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
        params: { requestID: requestID },
    });
  },
  
  listLiquidation(RequestID){    
    return Service.post(resource + "listLiquidation",{},{
    params:{RequestID: RequestID}
  });
 
},

GetUrllistLiquidation(){
  return Vue.prototype.$http.defaults.baseURL + "/incLiquidation/listLiquidation";
},

};