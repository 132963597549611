<template>
  
    <s-crud @returnObject='refresh($event)'
    no-border
    title="Incidencias por Liquidación"
    :config="config"
    @save="save($event)"
        add
    @clearForm="clearForm()"
    @rowSelected="rowSelected($event)"
    remove    
    :filter="filter"
    search-input  
    ref="crud"
    >
   <template scope="props">
       <v-card-text class="pa-0" >
        <v-row justify="center">

          <v-col cols="4" class="s-col-form" justfy="center" style="margin: initial;"  lg="4">
                <v-card class="pa-2" >
            <s-select-definition v-model="props.item.TypeInl" :def="1296" label="Tipo"></s-select-definition>
             </v-card>
          </v-col>
           <v-col cols="4" class="s-col-form" justfy="center" style="margin: initial;"  lg="4">
            <v-card class="pa-2">
            <s-text v-model="props.item.InlDescription" label="Descripción" > </s-text> 
             </v-card>
           </v-col>

 <v-col cols="4" class="s-col-form" justfy="center" style="margin: initial;"  lg="4">
            <v-card class="pa-2">
           <sselectLiquidationov v-model="props.item.LovID"label="Liquidación" ></sselectLiquidationov>
             </v-card>
           </v-col>

            <v-col lg="2" class="s-col-form" cols="12">
             <v-card class="pa-2">
            <s-date
              label="Fecha"
              v-model="props.item.InlDate"
            
                          ></s-date>
                           </v-card>
          </v-col>
           
        </v-row> 
       
      </v-card-text>  
         </template>
           <template v-slot:SecStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.SecStatus == 1 ? 'success' : 'error'">
          {{ row.SecStatus == 1 ? "Registrada" : "Eliminada" }}
        </v-chip>
      </template>

           
     </s-crud>

</template>


<script>
import _service from "@/services/FreshProduction/IncidenceLiquidationService";
import sselectLiquidationov from "@/components/FreshProduction/LiquidationOV/sSelectLiquidationOV.vue";
export default {
  name: "RequirementServiceIncidenceContainer",
  components: { sselectLiquidationov 
  },
  props: {
    parentID: { type: Number, default: 0 },
    addNoFunction: { type: Boolean, default: false },
  },

  data() {
    return {
      inlID:0,
      dialogEdit: false,
      
       itemsSelect: [],
       itemsFarm: [], 
       filter: { },  
     };     
  },

  computed: {
   
    config() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          InlID: "ID",
          InlDescription: "string",         
          DedDescription: "string", 
          LovID: "int",
          SecStatus: "status",
          }, 
        service: _service,
        headers:  this.$fun.getSecurity().IsLevelAdmin  
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "InlID", sortable: false },
              { text: "Descripción", value: "InlDescription", sortable: false },
              { text: "Tipo", value: "DedDescription", sortable: false }, 
               { text: "Liquidación", value: "LovID", sortable: false },                
               { text: "Estado", value: "SecStatus", sortable: false }, 
                     
            ]
          : [ 
                // CABECERA EN CASO NO SE LOGEE COMO ADMIN            
             { text: "ID", value: "InlID", sortable: false },
              { text: "Descripción", value: "InlDescription", sortable: false },
              { text: "Tipo", value: "DedDescription", sortable: false }, 
               { text: "Liquidación", value: "LovID", sortable: false },                
               { text: "Estado", value: "SecStatus", sortable: false },     
            ],
      };
    }, 
    
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
         },  
  },
  

  methods: {

 Initialize() {
   //DedID: this.DedID -- base de datos: campo actual
     this.filter = {};
    },
   
    edit(item) {
      this.dialogEdit = true;
    
    },

    rowSelected(rows) {
      if (rows.length > 0) {
      this.inlID = rows[0].InlID;
      this.$emit("rowSelected",  rows[0]); 
       this.itemsSelect=rows[0];
      }
    },

    save(item) {  

      if (item.InlDescription.length==0)
      {
             this.$fun.alert("Debe ingresar una descripcion para la actividad","warning")
             return;
      }

        item.SecStatus=1
       
        item.save();          
      
    },    
  },
  watch: {
    
    typeArea(){   
        
      this.Initialize();    
      },
  },
   created() {
    this.filter = { TypeArea: 0 };
  },
};
</script>
